import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../components/layout/layout";
import Seo from "../components/misc/Seo/seo";

const Page = ({ data, pageContext }) => {
    const menus = pageContext.menus?.items;
    const page = data.mdx;
    const heroImage = page?.frontmatter?.pageHeroImage?.publicURL ?? null;
    const pageClasses = page.slug && page.slug.length > 0 ? page.slug.replace("/", " ") : "index";
    const extraBodyClasses = page?.frontmatter?.body_class ?? "";

    return (
        <Layout
            menus={menus}
            headerType={pageContext.type}
            pageHeading={page.frontmatter.pageHeading}
            pageSubHeading={page.frontmatter.pageSubHeading}
            pageHeroImage={heroImage}
            dual_cta={page.frontmatter.dual_cta}
            ctaClasses={`st-dual-ctas`}
        >
            <Seo
                type={"Page"}
                title={page.frontmatter.pageHeading || page.frontmatter.title}
                description={page.frontmatter.description}
                slug={page.fields.slug}
                bodyClass={`${pageContext.type} ${pageClasses} ${page.slug} ${extraBodyClasses}`}
            />
            <MDXRenderer>{page.body}</MDXRenderer>
        </Layout>
    );
};

export default Page;

export const query = graphql`
    query($slug: String!) {
        mdx(fields: { slug: { eq: $slug } }) {
            id
            body
            slug
            frontmatter {
                changefreq
                description
                dual_cta
                layout
                sitemap_priority
                title
                pageHeading
                pageSubHeading
                pageHeroImage {
                    publicURL
                }
                body_class
            }
            fields {
                slug
            }
        }
    }
`;
